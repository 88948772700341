import React, { Component } from 'react';
import GallerySideInfo from './gallerySideInfo';
import Paragraph from './paragraph';
import Crossfade from './crossfade';

class GalleryAbout extends Component {
  render() {
    const { galleryData } = this.props;

    // Crossfade images for fall opening reception
    const crossfadeImages = [
      {
        src: 'https://tahoeartleague.org/uploads/crossfades/fall-show-opening-23/opening-1.jpg',
        alt: 'lady holding a painting',
      },
      {
        src: 'https://tahoeartleague.org/uploads/crossfades/fall-show-opening-23/opening-2.jpg',
        alt: 'girl sitting infront of painting',
      },
      {
        src: 'https://tahoeartleague.org/uploads/crossfades/fall-show-opening-23/opening-3.jpg',
        alt: 'two people holding a painting',
      },
      {
        src: 'https://tahoeartleague.org/uploads/crossfades/fall-show-opening-23/opening-4.jpg',
        alt: 'two people holding a painting',
      },
      {
        src: 'https://tahoeartleague.org/uploads/crossfades/fall-show-opening-23/opening-5.jpg',
        alt: 'two people holding a painting',
      },
      {
        src: 'https://tahoeartleague.org/uploads/crossfades/fall-show-opening-23/opening-6.jpg',
        alt: 'lady holding a painting',
      },
      {
        src: 'https://tahoeartleague.org/uploads/crossfades/fall-show-opening-23/opening-7.jpg',
        alt: 'two people holding a pointing',
      },
    ];

    return (
      <div className="bottomMargin-container">
        <h2>{galleryData.heading}</h2>
        <div className="aboutCart-row">
          <div className="aboutCart-p">
            {' '}
            {/* Was <p> before. */}
            {galleryData.paragraph && (
              <Paragraph text={galleryData.paragraph} />
            )}
            {/* 2025 Show Schedule Section. */}
            <section
              style={{ marginTop: '3rem', marginBottom: '3rem' }}
              id="show-application"
            >
              <h3>2025 Tahoe Art League Member Art Show Schedule:</h3>
              <p>
                Winter Show ends <strong>March 2nd</strong>. Work should be
                picked up and dropped off March 2nd-5th. Spring Show Opening
                Reception <strong>March 7th</strong> at 5:30.
              </p>
              <p>
                Spring Show ends <strong>June 1st</strong>. Work should be
                picked up and dropped off June 1st-4th. Summer Show Opening
                Reception <strong>June 6th</strong> at 5:30.
              </p>
              <p>
                Summer Show ends <strong>August 31st</strong>. Work should be
                picked up and dropped off August 31-Sept. 3rd. Fall Show Opening
                Reception <strong>Sept. 5th</strong> at 5:30.
              </p>
              <p>
                Fall Show ends <strong>Nov. 9th</strong>. Work should be picked
                up and dropped off Nov. 9-12th. Winter Show Opening Reception{' '}
                <strong>November 14th</strong> at 5:30.
              </p>
              <p>
                To show and sell your work in our Gallery please contact the
                Gallery Director: Elle Monsos,{' '}
                <a href="mailto:artbyseasons@yahoo.com">
                  artbyseasons@yahoo.com
                </a>{' '}
                or <a href="mailto:info@talart.org">info@talart.org</a>.
              </p>
            </section>
            {/* Show Application Section. */}
            <section
              style={{ marginTop: '3rem', marginBottom: '3rem' }}
              id="show-application"
            >
              <h3>Fall Show Opening and Studio Tour Passport Drawing</h3>
              {/* <p>
                Tahoe Art League’s Summer Show will be on view May through
                August 2023.
                <br />
                You can now{' '}
                <a
                  href="/assets/show-submissions.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  view and download the show submission guide
                </a>
                .
              </p> */}
              <Crossfade crossfadeImages={crossfadeImages} />
              <p>
                We all had a great time at this years fall show opening
                reception and Studio Tour passport drawing. Please stop by our
                gallery and enjoy award winning work by top local artists.
              </p>
            </section>
          </div>
          <GallerySideInfo galleryData={galleryData} />
        </div>
      </div>
    );
  }
}

export default GalleryAbout;
